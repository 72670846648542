body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

span{
    display: inline-block!important;
}

body, button, input, optgroup, select, textarea {
    font-size: 12px/1.5;
    font-family: arial,Helvetica Neue,tahoma,"Hiragino Sans GB",Microsoft YaHei,\5b8b\4f53;
    line-height: 1.5;
}

svg:not(:root){
  vertical-align: middle;
}

.resource-container .img_wrapper{
  margin: 1em 0em;
}
.resource-container .img_wrapper img{
    display: block;
    margin: auto;
}
.resource-container {
    word-break: break-word;
}
pre {
    overflow-x: scroll;
}

*{
  max-width: 100%!important;
}

img{
  max-width: 100%!important;
  height: auto !important;
}

.post_text{
  border-top: 0px!important;
}

a{
  color: black;
}
